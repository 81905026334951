<template>
  <div class="pageview">

    <van-sticky>
      <van-row>
        <van-col span="7"
          style="font-size: 16px;"
          class="call van-ellipsis--l1">
          <div class="sousuo"
            @click="showPopup">{{dataForm.OName}}
            <van-icon name="arrow-down" />
          </div>

          <van-popup v-model="show"
            round
            position="bottom">
            <van-cascader title="请选择所在地区"
              :options="options"
              @close="show = false"
              :field-names="{
                text: 'OrganName',
                value: 'OrganCode',
                children: 'children',
              }"
              @finish="onFinish" />
          </van-popup>
        </van-col>
        <van-col span="17">
          <van-search style="padding-left:0;"
            v-model="dataForm.name"
            shape="round"
            placeholder="请输入搜索关键词" />
        </van-col>
      </van-row>

    </van-sticky>

    <div class="header">
      <div class="personal"
        style="text-align: center;">
        <van-swipe class="homebanner"
          :autoplay="3000"
          indicator-color="white">
          <van-swipe-item v-for="item in imgsList"
            :key="item">
            <!-- <video v-if="imgList.video != ''"
              :poster="imgList.video"
              id="myVideo">
            </video> -->
            <img v-if="imgList.imgs != ''"
              :src="item" />

          </van-swipe-item>
        </van-swipe>
      </div>
    </div>
    <div style="margin:0 10px;">
      <van-grid class="wktype"
        style="padding:5px 0!important;margin:2px;"
        :border="false"
        :square="true">
        <van-grid-item icon="photo-o"
          @click="gotoUrl('/travel/jingqu')">
          <van-image class="iconimg"
            :src="require('../../assets/乡村景区.png')"></van-image>
          <span class="wktext">乡村景区</span>
        </van-grid-item>
        <van-grid-item icon="photo-o"
          @click="gotoUrl('/travel/store')">
          <van-image class="iconimg"
            :src="require('../../assets/便民商家.png')"></van-image>
          <span class="wktext">便民商家</span>
        </van-grid-item>
        <van-grid-item icon="photo-o"
          @click="gotoUrl('/travel/jingqu/jqXiangQIng/hotel/1')">
          <van-image class="iconimg"
            :src="require('../../assets/酒店住宿.png')"></van-image>
          <span class="wktext">酒店住宿</span>
        </van-grid-item>
        <van-grid-item icon="photo-o"
          @click="gotoUrl('/travel/outdoorLife')">
          <van-image class="iconimg"
            :src="require('../../assets/户外生活.png')"></van-image>
          <span class="wktext">户外生活</span>
        </van-grid-item>
        <van-grid-item icon="photo-o"
          @click="gotoUrl('/travel/deliciousFood')">
          <van-image class="iconimg"
            :src="require('../../assets/特色美食.png')"></van-image>
          <span class="wktext">特色美食</span>
        </van-grid-item>
        <van-grid-item icon="photo-o"
          @click="gotoUrl('/travel/specialty')">
          <van-image class="iconimg"
            :src="require('../../assets/农家特产.png')"></van-image>
          <span class="wktext">农家特产</span>
        </van-grid-item>
        <van-grid-item icon="photo-o"
          @click="gotoUrl('/travel/gonglue')">
          <van-image class="iconimg"
            :src="require('../../assets/乡村攻略.png')"></van-image>
          <span class="wktext">旅游攻略</span>
        </van-grid-item>
        <van-grid-item icon="photo-o"
          @click="gotoUrl('/travel/pick')">
          <van-image class="iconimg"
            :src="require('../../assets/采摘园.png')"></van-image>
          <span class="wktext">采摘园</span>
        </van-grid-item>
      </van-grid>
    </div>
    <!-- <div class="fjJd">
      <div style="float:left;line-height: 35px;">
        <div class="typeTitle fujinJd">&nbsp;附近景点</div>
        <div class="van-ellipsis--l"
          style="width:55%;float:left;">
          <span style="color:#FE5B47;">&nbsp;&nbsp;&nbsp;HOT&nbsp;</span>
          <span style="color:#666666;">龙门石窟十一门票团购价</span>
        </div>
        <div style="float:right;color:#999999;"
          @click="gotoUrl('/article')">更多&nbsp;</div>
      </div>
    </div> -->
    <!-- <div>
      <div>附近景点</div>
    </div> -->
    <div style="width:95%;margin:auto;">
      <div style="margin-bottom:20px;">
        <div class="title">—&nbsp;&nbsp;人气推荐&nbsp;&nbsp;—</div>
      </div>
      <div style="margin: 10px 0;font-weight: bold;">
        <van-swipe class="homebanner"
          :autoplay="3000"
          :width="160"
          :show-indicators="false"
          indicator-color="white">
          <van-swipe-item v-for="item in jqTjList"
            :key="item.SSId">
            <div @click="tuijianClick(item.SSId)">
              <img v-if="item.ImgUrls == ''"
                style="width:150px;height:100px;"
                src="https://xczxapi.zhsq.cloud//upload/images/2022/5/5a80ec81-50a.png" />
              <img v-else
                style="width:150px;height:100px;"
                :src="item.ImgUrls.split(',')[0]" />

              <div style="margin-top:10px;margin-bottom:5px;color:#0C0C0C;">{{item.Name}}</div>
              <div>
                <span style="color:#FE5B47;">￥{{item.Cost}}</span>
                <span style="font-weight: 300;font-size:13px;">起</span>
              </div>
            </div>

          </van-swipe-item>
        </van-swipe>
      </div>
    </div>

    <div style="width:95%;margin:auto;">
      <div>
        <div class="grouptitle">&nbsp;</div>
        <div class="typeTitle"
          style="margin:1em 0 0.5em;">特色美食
          <span style="color:#999999;float: right;font-size:12pt;font-weight: 300;"
            @click="gotoUrl('/travel/deliciousFood')">更多
            <van-icon name="arrow" /></span>
        </div>
      </div>
      <div class="information">
        <div class="center"
          style="padding:0;"
          v-for="item in SpecialtyPageList"
          :key="item.index">
          <div @click="gotoUrl('/travel/deliciousFood/foodXq/' + item.EateryId)">
            <van-card style="padding: 10px 0px;background-color: white;">
              <template #thumb>
                <div class="van-card__thumb">
                  <img v-if="item.ImgUrls == ''"
                    style="width:100%;height:100%;"
                    src="https://xczxapi.zhsq.cloud//upload/images/2022/5/5a80ec81-50a.png" />
                  <img v-else
                    style="width:100%;height:100%;"
                    :src="item.ImgUrls.split(',')[0]" />
                </div>

              </template>
              <template #title>
                <div class="centerTitle van-ellipsis--l1"
                  style="width: 75%;">{{ item.Name }}</div>
                <div class="county">{{item.OrgName}}</div>
              </template>

              <template #tags>
                <van-tag class="tagClass"
                  plain
                  type="danger"
                  color="#ffe1e1"
                  text-color="#FE5B47">{{item.Range}}</van-tag>
                <div style="width: 75%;font-size:13px;"
                  class="van-multi-ellipsis--l2">{{item.Address}}</div>
              </template>
              <template #num>
                <div style="padding-right:5px;">
                  <!-- <span style="font-size:18px;font-weight: 550;color:red;">￥{{item.money}}</span> -->
                  &nbsp;{{item.BusinessTime}}</div>
              </template>
            </van-card>
          </div>
        </div>
      </div>
    </div>
    <!-- 猜你喜欢 -->
    <!-- <div style="width:95%;margin:auto;">
      <div>
        <div class="grouptitle">&nbsp;</div>
        <div class="typeTitle"
          style="margin:1em 0 0.5em;">猜你喜欢
          <span style="color:#999999;float: right;font-size:12pt;font-weight: 300;"
            @click="gotoUrl('/travel/jingqu')">更多
            <van-icon name="arrow" /></span>
        </div>
      </div>
      <div class="information">
        <div class="center"
          style="padding:0;"
          v-for="item in jqLikeList"
          :key="item.index">
          <div @click="tuijianClick(item.SSId)">
            <van-card style="padding: 8px 0px;background-color: white;">
              <template #thumb>
                <div class="van-card__thumb">
                  <img v-if="item.ImgUrls == ''"
                    style="width:100%;height:100%;"
                    src="https://xczxapi.zhsq.cloud//upload/images/2022/5/5a80ec81-50a.png" />
                  <img v-else
                    style="width:100%;height:100%;"
                    :src="item.ImgUrls.split(',')[0]" />
                </div>

              </template>
              <template #title>
                <div class="centerTitle van-ellipsis--l1"
                  style="width: 75%;">{{ item.Name }}</div>
                <div class="county">{{item.OrgName}}</div>
              </template>

              <template #tags>
                <van-tag class="tagClass"
                  plain
                  type="danger"
                  color="#ffe1e1"
                  text-color="#FE5B47">人气景点</van-tag>
                <div style="width: 75%;"
                  class="van-multi-ellipsis--l2">{{item.Introduce}}</div>
              </template>
              <template #num>
                <div><span style="font-size:18px;font-weight: 550;color:red;">￥{{item.Cost}}</span>&nbsp;起</div>
              </template>
            </van-card>
          </div>
        </div>
      </div> -->

    <!-- 酒店住宿 -->
    <div style="width:95%;margin:auto;">
      <div>
        <div class="grouptitle">&nbsp;</div>
        <div class="typeTitle"
          style="margin:1em 0 0.5em;">酒店住宿
          <span style="color:#999999;float: right;font-size:12pt;font-weight: 300;"
            @click="gotoUrl('/travel/jingqu/jqXiangQIng/hotel/1')">更多
            <van-icon name="arrow" /></span>
        </div>
        <div class="information">
          <div class="center"
            style="padding:0;"
            v-for="item in hotelList"
            :key="item.index">
            <div @click="hotelClick('/travel/jingqu/jqXiangQIng/hotel/hotelXq/'+ item.HotelId)">
              <van-card style="padding: 10px 0px;background-color: white;">
                <template #thumb>
                  <div class="van-card__thumb">
                    <img v-if="item.ImgUrls == ''"
                      style="width:100%;height:100%;"
                      src="https://xczxapi.zhsq.cloud//upload/images/2022/5/5a80ec81-50a.png" />
                    <img v-else
                      style="width:100%;height:100%;"
                      :src="item.ImgUrls.split(',')[0]" />
                  </div>

                </template>
                <template #title>
                  <div class="centerTitle van-ellipsis--l1"
                    style="width: 75%;">{{ item.Name }}</div>
                  <div class="county">{{item.OrgName}}</div>
                </template>

                <template #tags>
                  <van-tag class="tagClass"
                    plain
                    type="danger"
                    color="#FFFFFF"
                    text-color="#66D161">{{item.CategoryDesc}}</van-tag>
                  <div style="width: 75%;font-size:13px;"
                    class="van-multi-ellipsis--l2">{{item.Introduce}}</div>
                </template>
                <template #num>
                  <div style="padding-right:5px;">
                    <!-- <span style="font-size:18px;font-weight: 550;color:red;">￥{{item.Cost}}</span> -->
                    <!-- &nbsp;起 -->
                    {{item.BusinessTime}}
                  </div>
                </template>
              </van-card>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br>
  </div>
</template>

<script>
import config from "@/config/index";
import wx from "weixin-js-sdk";
import { setOpenId, getOpenId, setOCode, getOCode, setOName, getOName, removeOName } from "@/utils/auth";
import { WeGetAppletInfo, WeGetRealInfoPage, WeGetOrganTree, WeGetAppletOrganList } from "@/api/index";
import { WeGetScenicSpotPageList, WeGetEateryPage, WeGetHotelPage } from "@/api/jingqu";
export default {
  components: {

  },
  data () {
    return {
      appInfo: {},
      bannerImgs: [],
      active: 0,
      imgList: {
        imgs: 'https://xczxapi.zhsq.cloud//upload/images/2022/6/ac9ed2a6-74a.png',
        video: '',
      },
      NoticeList: [
        { RIId: '1', Title: '洛阳水席>牡丹燕菜', ThematicUrl: 'https://xczxapi.zhsq.cloud//upload/images/2022/5/fda33f6c-26d.png', adddress: '洛阳水席，是河南洛阳一带特色传统名宴，属于豫菜系。洛阳水席', money: '20' },
        { RIId: '2', Title: '洛阳不翻汤', ThematicUrl: 'https://xczxapi.zhsq.cloud//upload/images/2022/5/99ebd0d2-b08.png', adddress: '洛阳水席，是河南洛阳一带特色传统名宴，属于豫菜系。洛阳水席', money: '38' },
      ],
      LikeList: [
        { RIId: '1', Title: '老君山风景区', ThematicUrl: 'https://xczxapi.zhsq.cloud//upload/images/2022/5/5a80ec81-50a.png', adddress: '老君山形成于十九亿年前的大陆造山运动，它记录着19亿年来华北古陆块南缘裂解、离散、增生、聚合、碰撞、造山等构造演化过程', money: '88' },
        { RIId: '2', Title: '木札岭旅游区', ThematicUrl: 'https://xczxapi.zhsq.cloud//upload/images/2022/5/fe054bfd-38b.png', adddress: '木札岭原始生态旅游区，位于洛阳市嵩县车村镇境内，地处伏牛山', money: '70' },
      ],
      area: '',
      dataForm: {
        name: '',
        OName: '',
        page: 1,
        limit: 5,
        orgCode: '',
      },
      show: false,
      options: [],
      imgsList: [],
      // 景区推荐
      jqTjList: [],
      // 猜你喜欢
      jqLikeList: [],
      // 特色美食
      SpecialtyPageList: [],
      // 酒店住宿
      hotelList: [],
    };
  },
  created () {
    console.log(getOName());
    if (getOName() != undefined) {
      console.log(123);
      this.dataForm.OName = getOName()
      this.dataForm.orgCode = getOCode()
      this.gitocodelist()
    } else {
      this.gitocodelistSc()
    }
    this.getAppInfo()
    // 景区推荐
    this.getScenicSpotPageList()
    // 猜你喜欢
    // this.getScenicSpotPageList()
  },
  methods: {
    gitocodelist () {
      WeGetAppletOrganList().then((res) => {
        this.options = res.data.data;
      })
    },
    gitocodelistSc () {
      WeGetOrganTree().then((res) => {

        this.options = res.data.data;
        // setOCode(this.options[0].OCode);
        setOName(this.options[0].OName);
        // this.dataForm.orgCode = this.options[0].OCode
        this.dataForm.OName = this.options[0].OName
      })
    },
    // 景区推荐
    getScenicSpotPageList () {
      WeGetScenicSpotPageList(this.dataForm).then((res) => {
        this.jqTjList = res.data.data;
      })
      // 猜你喜欢
      // WeGetScenicSpotPageList(this.dataForm).then((res) => {
      //   this.jqLikeList = res.data.data;
      // })
      // 特色美食
      WeGetEateryPage(this.dataForm).then((res) => {
        this.SpecialtyPageList = res.data.data;
      })
      // 酒店住宿
      WeGetHotelPage(this.dataForm).then((res) => {
        this.hotelList = res.data.data;
      })
    },

    // 小程序展示数据
    getAppInfo () {
      console.log(this.imgList);
      if (this.imgList.imgs != '') {
        this.imgsList = this.imgList.imgs.split(",")
        console.log(this.imgsList);
      }
      // WeGetAppletInfo().then((res) => {
      //   var list = res.data;
      //   if (list.code == 0) {
      //     var imgs = [];
      //     imgs[0] = { img: list.data.SysPic1Url };
      //     imgs[1] = { img: list.data.SysPic2Url };
      //     if (list.data.SysPic1Url != '') {

      //     }
      //     // imgs[2] = list.data.SysPic3Url;
      //     this.bannerImgs = imgs;
      //     console.log(this.bannerImgs);
      //     this.appInfo = list.data;
      //   }
      // });
    },
    // 通知公告
    getNoticeList () {
      WeGetRealInfoPage({ page: 1, limit: 5, sortId: 0 }).then((res) => {
        this.NoticeList = res.data.data;
      });
    },
    //跳转到公共页面
    gotoUrl (path) {
      if (window.__wxjs_environment === "miniprogram") {
        window.wx.miniProgram.navigateTo({
          url: "../public/goto?path=" + path,
        });
      } else {
        this.$router.push(path);
      }
    },
    showPopup: function () {
      this.show = true
    },
    onFinish: function (val) {
      this.dataForm.OName = val.selectedOptions[0].OrganName;
      if (this.dataForm.OName == '洛阳市') {
        this.dataForm.orgCode = '';
      } else {
        this.dataForm.orgCode = val.value;
      }
      console.log(this.dataForm);
      setOCode(this.dataForm.orgCode);
      setOName(this.dataForm.OName);
      // removeOName()
      // console.log(getOName());
      // this.dataForm.OCode = val.value;
      this.show = false
      this.getScenicSpotPageList()
    },
    tuijianClick: function (row) {
      console.log(row);
      this.$router.push('/travel/jingqu/jqXiangQIng/' + row);
    },
    // 酒店详情
    hotelClick: function (path) {
      this.$router.push(path);
    },
  },
};
</script>

<style scoped>
.grouptitle {
  border-left: 4px solid #4add93;
  float: left;
  margin: 5px;
}
.homebanner img {
  width: 95%;
  vertical-align: top;
  margin: auto;
  border-radius: 5px;
}
.wktype .iconimg {
  margin-bottom: 5px;
  width: 55%;
}
.wktext {
  font-size: 11pt;
  color: #0c0c0c;
}
.van-ellipsis--l {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-size: 16px;
}
.title {
  font-size: 1.17em;
  font-weight: bold;
  margin-top: 1em;
  text-align: center;
}
.ContentA {
  display: inline-block;
  width: 32%;
  margin-left: 15px;
  margin-top: 3px;
}
.van-ellipsis--l1 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-size: 16px;
}
.tagClass {
  margin: 10px 0;
}
.sousuo {
  height: 54px;
  background: white;
  line-height: 54px;
  padding-left: 10%;
  margin: auto;
  color: red;
}
.fjJd {
  width: 95%;
  margin: auto;
  display: grid;
  margin-bottom: 1em;
}
.fujinJd {
  float: left;
  color: #fe5b47;
  padding: 0 5px;
  font-size: 14pt;
  font-weight: bold;
}
.typeTitle {
  font-size: 16pt;
  font-weight: bold;
}
.van-card__thumb {
  position: relative;
  -webkit-box-flex: 0;
  -webkit-flex: none;
  flex: none;
  width: 110px;
  height: 110px;
  margin-right: 12px;
}
.county {
  text-align: right;
  position: absolute;
  top: 5px;
  right: 5px;
}
</style>